import {defineStore} from "pinia";
import {IndexCompetition} from "@/types/Competition";
import {registrationsCalendarService} from "@/modules/home/registrations/service/RegistrationsCalendarService";
import {useCalendarFilterStore} from "@/modules/home/filter/store/CalendarFilterStore";

export const useRegistrationsStore = defineStore('registrationsStore', {
    state: () => {
        return {
            loading: false,

            closing: [] as Array<IndexCompetition>,
            open: [] as Array<IndexCompetition>,
            soon: [] as Array<IndexCompetition>,
        }
    },
    getters: {
        hasCompetitions(): boolean {
            return (
                this.closing.length > 0 ||
                this.open.length > 0 ||
                this.soon.length > 0
            )
        }
    },
    actions: {
        reload() {
            this.startLoading()
            Promise.all([
                this.loadAll()
            ])
                .then(() => {
                    this.stopLoading()
                })
        },
        async loadAll() {
            const response = await registrationsCalendarService.getCalendar(useCalendarFilterStore().getFilterContext)
            this.closing = response.closing
            this.open = response.open
            this.soon = response.soon
        },
        startLoading() {
            this.loading = true
        },
        stopLoading() {
            this.loading = false
        }
    }
})