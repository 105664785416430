import {IndexCompetition} from "@/types/Competition";
import {httpService} from "@/util/HttpService";
import {SearchFilter} from "@/modules/home/filter/service/SearchFilterService";

class RegistrationsCalendarService {
    getCalendar(filter: SearchFilter | undefined): Promise<RegistrationsCalendarResponse> {
        return httpService.post("/api/mobile/public/registrations/calendar", filter)
    }
}

export interface RegistrationsCalendarResponse {
    closing: Array<IndexCompetition>,
    open: Array<IndexCompetition>,
    soon: Array<IndexCompetition>
}

export const registrationsCalendarService = new RegistrationsCalendarService();