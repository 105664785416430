import {defineStore} from "pinia";
import {myCalendarFilterStoreService} from "@/storage/StorageService";
import {
    SearchFilter,
    SearchFilterChannel,
    searchFilterService
} from "@/modules/home/filter/service/SearchFilterService";

const MY_CALENDAR_FILTER_STORAGE_KEY = "eqify-calendar-preferences"

interface FilterStore {
    enabled: boolean,
    sportTypes: Array<SelectableFilterItem>,
    channels: Array<ChannelFilterItem>
    keyword: undefined | string,
}

export const useCalendarFilterStore = defineStore('calendarFilterStore', {
    state: () => {
        return {
            enabled: false,
            sportTypes: [] as Array<SelectableFilterItem>,
            channels: [] as Array<ChannelFilterItem>,
            keyword: "",
        } as FilterStore
    },
    actions: {
        async saveFilter() {
            await this.save(MY_CALENDAR_FILTER_STORAGE_KEY, {
                enabled: this.enabled,
                channels: this.channels.filter(it => it.selected)
                    .map(it => {
                        return {
                            channelId: it.id,
                            regionIds: it.regions.filter(it => it.selected).map(it => it.id)
                        } as FilterChannel
                    }),
                sportTypes: this.sportTypes.filter(it => it.selected).map(it => it.id),
                keyword: this.keyword,
            } as FilterStorageData)
        },
        async fetchFilter() {
            const data: FilterStorageData | null = await this.get(MY_CALENDAR_FILTER_STORAGE_KEY)

            if (data) {
                this.enabled = data.enabled
                this.sportTypes = this.sportTypes.map(it => {
                    const isSelected = (data.sportTypes || []).find(id => id == it.id) != null
                    return {
                        ...it,
                        selected: isSelected
                    }
                })
                this.channels = this.channels.map(channel => {
                    const dChannel = (data.channels || []).find(id => id.channelId == channel.id)
                    return {
                        id: channel.id,
                        name: channel.name,
                        selected: dChannel != null,
                        regions: channel.regions.map(region => {
                                return {
                                    id: region.id,
                                    name: region.name,
                                    selected: (dChannel?.regionIds || []).find(id => id == region.id) != null
                                } as SelectableFilterItem
                            }
                        )
                    } as ChannelFilterItem
                })
                this.keyword = data.keyword
            } else {
                this.enabled = false
            }
        },
        async get(key: string): Promise<FilterStorageData | null> {
            const response = await myCalendarFilterStoreService.get(key)
            if (response) {
                return JSON.parse(response) as FilterStorageData
            } else return null
        },
        async save(key: string, context: FilterStorageData) {
            await myCalendarFilterStoreService.set(
                key,
                JSON.stringify(context)
            )
        },
        async loadCriteria() {
            const response = await searchFilterService.getMetadata()

            this.sportTypes = response.sportTypes.map(it => {
                return {
                    id: it,
                    name: it,
                    selected: false
                } as SelectableFilterItem
            })

            this.channels = response.channels.map(it => {
                return {
                    id: it.channelId,
                    name: it.name,
                    selected: false,
                    regions: it.regions.map(it => {
                        return {
                            id: it.regionId,
                            name: it.name,
                            selected: false
                        } as SelectableFilterItem
                    })
                } as ChannelFilterItem
            })

            await this.fetchFilter()
        }
    },
    getters: {
        getFilterContext(): SearchFilter | undefined {
            if (this.enabled) {
                return {
                    enabled: true,
                    sportTypes: this.sportTypes
                        .filter(it => it.selected)
                        .map(it => it.id),
                    channels: this.channels
                        .filter(it => it.selected)
                        .map(it => {
                            return {
                                channelId: it.id,
                                regionIds: it.regions.filter(it => it.selected).map(it => it.id)
                            } as SearchFilterChannel
                        }),
                    keyword: this.keyword
                } as SearchFilter
            } else {
                return undefined
            }
        },
        countActiveFilters(): number {
            if (!this.enabled) {
                return 0
            } else {
                let c = 0
                if (this.keyword) {
                    c++
                }
                if (this.channels.filter(it => it.selected == true).length > 0) {
                    c++
                }
                if (this.sportTypes.filter(it => it.selected == true).length > 0) {
                    c++
                }
                return c
            }
        }
    }
})

export interface FilterStorageData {
    enabled: boolean,
    sportTypes: Array<string>,
    channels: Array<FilterChannel>
    keyword: string | undefined
}

export interface FilterChannel {
    channelId: string,
    regionIds: Array<string>
}

export interface SelectableFilterItem {
    id: string,
    name: string,
    selected: boolean
}

export interface ChannelFilterItem extends SelectableFilterItem {
    regions: Array<SelectableFilterItem>
}