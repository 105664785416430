import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
  IonToolbar,
  IonTitle,
  IonPage,
  IonButtons,
  IonBackButton,
  IonContent,
  IonButton
} from '@ionic/vue';
import {environments, saveStaticConfig, removeStaticConfig} from "@/config";
import {useKeycloak} from "@/authentication/vue-keycloak";
import {useI18n} from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConfigView',
  setup(__props) {

const keycloak = useKeycloak()
const {t} = useI18n()

function restoreProductionConfig() {
  removeStaticConfig()
      .then(reloadPage);
}

function applyStagingConfig() {
  saveStaticConfig(environments.staging)
      .then(reloadPage);
}

function applyDevelopmentConfig() {
  saveStaticConfig(environments.development)
      .then(reloadPage);
}

function applyMobileConfig() {
  saveStaticConfig(environments.mobile)
      .then(reloadPage);
}

function reloadPage() {
  window.location.href = '/';
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonToolbar), {
        mode: "ios",
        color: "transparant",
        class: "mt-2"
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonTitle), null, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("span", { class: "text_bold" }, "Change config", -1)
            ])),
            _: 1
          }),
          _createVNode(_unref(IonButtons), { slot: "start" }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonBackButton), {
                color: "primary",
                defaultHref: "/"
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(IonContent), { color: "light" }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonButton), {
            expand: "block",
            color: "medium",
            class: "ion-margin-start ion-margin-end ion-margin-bottom",
            onClick: restoreProductionConfig
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Restore production ")
            ])),
            _: 1
          }),
          _createVNode(_unref(IonButton), {
            expand: "block",
            color: "medium",
            class: "ion-margin-start ion-margin-end ion-margin-bottom",
            onClick: applyStagingConfig
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" Staging ")
            ])),
            _: 1
          }),
          _createVNode(_unref(IonButton), {
            expand: "block",
            color: "medium",
            class: "ion-margin-start ion-margin-end ion-margin-bottom",
            onClick: applyDevelopmentConfig
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode(" Development ")
            ])),
            _: 1
          }),
          _createVNode(_unref(IonButton), {
            expand: "block",
            color: "medium",
            class: "ion-margin-start ion-margin-end ion-margin-bottom",
            onClick: applyMobileConfig
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode(" Mobile ")
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})