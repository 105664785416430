import {httpService} from "@/util/HttpService";

class SearchFilterService {
    getMetadata(): Promise<SearchFilterMetadata> {
        return httpService.get(`/api/mobile/public/calendar/search/metadata`)
    }
}

export interface SearchFilterMetadata {
    sportTypes: Array<string>,
    channels: Array<SearchFilterMetadataChannel>
}

export interface SearchFilterMetadataChannel {
    channelId: string,
    name: string,
    regions: Array<SearchFilterMetadataRegion>
}

export interface SearchFilterMetadataRegion {
    regionId: string,
    name: string
}

export enum DateSearchFilter {
    THIS_YEAR = "THIS_YEAR",
    THIS_MONTH = "THIS_MONTH",
    THIS_WEEK = "THIS_WEEK",
    TODAY = "TODAY",
    LAST_YEAR = "LAST_YEAR",
    LAST_MONTH = "LAST_MONTH",
    LAST_WEEK = "LAST_WEEK",
    YESTERDAY = "YESTERDAY",
    NEXT_YEAR = "NEXT_YEAR",
    NEXT_MONTH = "NEXT_MONTH",
    NEXT_WEEK = "NEXT_WEEK",
    TOMORROW = "TOMORROW",
    CUSTOM = "CUSTOM"
}

export interface SearchFilter {
    enabled: boolean,
    sportTypes: Array<string>,
    channels: Array<SearchFilterChannel>,
    keyword: string | undefined
}

export interface SearchFilterChannel {
    channelId: string,
    regionIds: Array<string>
}

export interface CustomDateFilter {
    start: string | undefined,
    end: string | undefined,
}

export const searchFilterService = new SearchFilterService();