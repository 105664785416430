import {Preferences } from '@capacitor/preferences';
import {nanoid} from 'nanoid'

class StorageService {
    async set(key: string, value: string): Promise<void> {
        await Preferences.set({
            key: key,
            value: value
        })
    }

    async get(key: string): Promise<string | null> {
        const raw = await Preferences.get({key: key})
        return raw.value
    }

    async clear(key: string) {
        await Preferences.remove({key: key})
    }
}

export const storageService = new StorageService()

//TODO move to storageService
class MyCalendarFilterStoreService {
    async set(key: string, value: string): Promise<void> {
        await Preferences.set({
            key: key,
            value: value
        })
    }

    async get(key: string): Promise<string | null> {
        const raw = await Preferences.get({key: key})
        return raw.value
    }

    async clear(key: string) {
        await Preferences.remove({key: key})
    }
}

export const myCalendarFilterStoreService = new MyCalendarFilterStoreService()

const LOCALE_STORE_KEY = "locale"

class LocaleStoreService {
    async saveLocale(locale: string): Promise<void> {
        return Preferences.set({key: LOCALE_STORE_KEY, value: locale});
    }

    async getLocale(): Promise<string | null> {
        const response = await Preferences.get({key: LOCALE_STORE_KEY});
        return response.value;
    }
}

export interface SearchHistoryItem {
    query: string,
    id: string
}

const SEARCH_HISTORY_STORE_KEY = "search_history"
class SearchHistoryStoreService {
    async add(query: string): Promise<void> {
        const raw = await Preferences.get({key: SEARCH_HISTORY_STORE_KEY})
        const items = JSON.parse(raw.value || "[]")

        const existing = items.find((it: SearchHistoryItem) => it.query == query)

        if (existing) {
            const indexToDelete = items.findIndex((it: SearchHistoryItem) => it.id == existing.id)
            items.splice(indexToDelete, 1)
        }

        items.unshift({
            query: query,
            id: nanoid()
        })

        if (items.length > 3) {
            items.length = 3
        }

        return Preferences.set({
            key: SEARCH_HISTORY_STORE_KEY,
            value: JSON.stringify(items)
        })
    }

    async get(): Promise<Array<SearchHistoryItem>> {
        const raw = await Preferences.get({key: SEARCH_HISTORY_STORE_KEY})
        return JSON.parse(raw.value || "[]")
    }

    async delete(id: string): Promise<void> {
        const raw = await Preferences.get({key: SEARCH_HISTORY_STORE_KEY})
        const items = JSON.parse(raw.value || "[]")
        const filteredItems = items.filter((item: any) => item.id !== id);

        return Preferences.set({
            key: SEARCH_HISTORY_STORE_KEY,
            value: JSON.stringify(filteredItems)
        })
    }

    async deleteAll() {
        await Preferences.remove({key: SEARCH_HISTORY_STORE_KEY})
    }
}

export const localeStoreService = new LocaleStoreService();
export const searchHistoryStoreService = new SearchHistoryStoreService();