import {Competition, IndexCompetition} from "@/types/Competition";
import {httpService} from "@/util/HttpService";
import {CompetitionTest} from "@/types/CompetitionTest";
import {Schedule} from "@/types/Schedule";
import {EqifyOnlineCompetitionSettings} from "@/types/Settings";

class CompetitionService {

    loadLiveCompetitions(): Promise<Array<IndexCompetition>> {
        return httpService.get("/api/mobile/public/competitions/live")
    }

    loadThisWeekCompetitions(): Promise<Array<IndexCompetition>> {
        return httpService.get("/api/mobile/public/competitions/this-week")
    }

    loadOther(): Promise<CompetitionsOverview> {
        return httpService.get("/api/mobile/public/competitions/other-v2")
    }

    loadCompetition(ref: string): Promise<IndexCompetition> {
        return httpService.get(`/api/mobile/public/competitions/${ref}`)
    }

    loadCompetitionOverview(ref: string): Promise<CompetitionOverview> {
        return httpService.get(`/api/mobile/public/competitions/${ref}/overview`)
    }

    loadAllLater(): Promise<Array<IndexCompetition>> {
        return httpService.get(`/api/mobile/public/competitions/later/all-v2`)
    }

    loadAllOlder(): Promise<Array<IndexCompetition>> {
        return httpService.get(`/api/mobile/public/competitions/older/all-v2`)
    }
}

export interface CompetitionOverview {
    competition: IndexCompetition,
    tests: Array<CompetitionTest>,
    schedule?: Schedule,
    settings?: EqifyOnlineCompetitionSettings
}

export interface CompetitionsOverview {
    lastWeek: Array<IndexCompetition>,
    nextWeek: Array<IndexCompetition>,
    later: Array<IndexCompetition>,
    older: Array<IndexCompetition>
}

export const competitionService = new CompetitionService()